import { ChangeDarkColor } from 'commands/business/Colors/ChangeDarkColor';
import { ChangeGreyColor } from 'commands/business/Colors/ChangeGreyColor';
import { ChangeLightColor } from 'commands/business/Colors/ChangeLightColor';
import { ChangeLinkColor } from 'commands/business/Colors/ChangeLinkColor';
import { ChangeLinkOnDarkColor } from 'commands/business/Colors/ChangeLinkOnDarkColor';
import { ChangeLinkOnGreyColor } from 'commands/business/Colors/ChangeLinkOnGreyColor';
import { ChangeLinkOnLightColor } from 'commands/business/Colors/ChangeLinkOnLightColor';
import { ChangeTertiaryColor } from 'commands/business/Colors/ChangeTertiaryColor';
import { ChangeTextColor } from 'commands/business/Colors/ChangeTextColor';
import { CommandBatch } from 'commands/Commands';

import BusinessColorsInput from 'components/MyBusiness/Inputs/BusinessColorsInput';

import { BusinessDetails } from 'models/business';

import BusinessBoard from 'components/MyBusiness/BusinessBoard';

import BusinessImagePicker from './Inputs/BusinessImagePicker';
import { ChangeLogoUrl } from 'commands/business/Colors/ChangeLogoUrl';
import { ChangeTextOnDarkColor } from 'commands/business/Colors/ChangeTextOnDarkColor';
import { ChangeTextOnLightColor } from 'commands/business/Colors/ChangeTextOnLightColor';
import { ChangeTextOnGreyColor } from 'commands/business/Colors/ChangeTextOnGreyColor';
import { ChangeFaviconUrl } from 'commands/business/ChangeFaviconUrl';

type Props = {
  business: BusinessDetails;
  commands: CommandBatch;
};
const ThemeFields = ({ business, commands }: Props) => {
  return (
    <BusinessBoard heading='Theme'>
      <BusinessImagePicker
        label={'Business Logo'}
        initialVal={business.details?.colors?.logoUrl}
        commandFactory={value => new ChangeLogoUrl(value)}
        commands={commands}
      />
      <BusinessImagePicker
        label={'Website Favicon'}
        initialVal={business.details?.faviconUrl}
        commandFactory={value => new ChangeFaviconUrl(value)}
        commands={commands}
      />

      {business?.details?.byob ? (
        <>
          <hr />
          <BusinessColorsInput
            label='Dark colour'
            initialVal={business.details?.colors?.darkColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeDarkColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Grey colour'
            initialVal={business.details?.colors?.greyColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeGreyColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Light colour'
            initialVal={business.details?.colors?.lightColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeLightColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Tertiary colour'
            initialVal={business.details?.colors?.tertiaryColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeTertiaryColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Text colour'
            initialVal={business.details?.colors?.textColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeTextColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Text on dark '
            initialVal={business.details?.colors?.textOnDarkColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeTextOnDarkColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Text on light'
            initialVal={business.details?.colors?.textOnLightColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeTextOnLightColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Text on grey'
            initialVal={business.details?.colors?.textOnGreyColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeTextOnGreyColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Link colour'
            initialVal={business.details?.colors?.linkColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeLinkColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Link on dark colour'
            initialVal={business.details?.colors?.linkOnDarkColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeLinkOnDarkColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Link on grey colour'
            initialVal={business.details?.colors?.linkOnGreyColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeLinkOnGreyColor(value)}
          />
          <hr />
          <BusinessColorsInput
            label='Link on light colour'
            initialVal={business.details?.colors?.linkOnLightColor ?? '#fff'}
            commands={commands}
            commandFactory={value => new ChangeLinkOnLightColor(value)}
          />
        </>
      ) : null}
    </BusinessBoard>
  );
};

export default ThemeFields;
